import * as URL from "url";
import React from "react";
import MainLayout from "../layout/MainLayout";
import { Link } from "gatsby";
import { TagList } from "../components/TagList";
import SEO from "../components/SEO";
import { Job } from "../typings";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const JobTemplate = ({ pageContext: { html, frontmatter } }: { pageContext: Job }) => {
  const { tags, company, title, location, applyUrl, sourceUrl, roleType, date } = frontmatter;
  let url = !applyUrl ? sourceUrl : applyUrl;
  url = addUTM(url);
  return (
    <MainLayout>
      <SEO title={`${title} at ${company.title}`} job={{ ...frontmatter, html }} />
      <Jumbotron company={company} location={location} roleType={roleType} title={title} url={url} />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
            <hr />
            <div className="text-center mb-5">
              <p>
                See more jobs at <Link to={`/companies/${company.id}`}>{company.title}</Link>
              </p>
              <OutboundLink className="btn btn-primary btn-lg" rel="nofollow" href={url}>
                <strong>Apply for this Job</strong>
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const Jumbotron = ({ location, company, roleType, title, url }) => (
  <div
    className="jumbotron jumbotron-fluid"
    style={{
      background: "#F7F9FC",
      borderBottom: "1px solid #DEE0F2",
    }}
  >
    <div className="container">
      <div className="row no-gutters">
        <div className="col-lg-8 offset-lg-2">
          <h5>
            <Link to={`/companies/${company.id}`}>{company.title}</Link>
          </h5>
          <h1 style={{ fontSize: 40 }}>{title}</h1>
          <h5>{roleType && <TagList tags={[roleType]} />}</h5>
          {location && <div className="lead">{location} 📍</div>}
          {!location && (
            <div className="lead">
              <em>No location specified</em>
            </div>
          )}
        </div>
        <div
          className="col-lg-8 offset-lg-2"
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            verticalAlign: "center",
          }}
        >
          <OutboundLink className="btn btn-primary btn-lg mt-4" rel="nofollow" href={url}>
            Apply for this Job
          </OutboundLink>
        </div>
      </div>
    </div>
  </div>
);

const addUTM = (link: string) => {
  let parsedURL = URL.parse(link);
  const searchParams = new URLSearchParams(parsedURL.search);
  searchParams.set("utm_source", "platformjobs");
  searchParams.set("utm_medium", "job");
  searchParams.set("utm_campaign", "apply");
  parsedURL.search = searchParams.toString();
  return URL.format({ ...parsedURL });
};

export default JobTemplate;
