import React from "react";

export const TagList = ({ tags }) => {
  if (tags.length === 0) {
    return (
      <span>
        <div className={`badge`}>Unknown</div>
      </span>
    );
  }
  return tags.map((tag, key) => (
    <span key={key}>
      <div className={`badge badge-primary`}>{tag}</div>{" "}
    </span>
  ));
};
